import { useState, useEffect } from "react"
import axios from 'axios'
import { Button, Modal } from 'react-bootstrap'
import { XLg, CheckLg, Trash, Save2 } from "react-bootstrap-icons"
import {mess1List, mess2List, APICALL_URL} from '../hooks/data'
import { errorAff } from '../hooks/functions'

function Users({action, isOnline}){
    const userLocal = JSON.parse(localStorage.getItem("user"))
    const [loginItem, setLoginItem] = useState()
    const [pwdItem, setPwdItem] = useState()
    const [roleItem, setRoleItem] = useState()
    const [prenomItem, setPrenomItem] = useState()
    const [nomItem, setNomItem] = useState()
    const [idItem, setIdItem] = useState()
    const [texteItem, setTexteItem] = useState()
    const [success, setSuccess] = useState(false)
    const [mess1, setMess1] = useState(mess1List[0])
    const [mess2, setMess2] = useState(mess2List[0])
    const [errMsg, setErrMsg] = useState('')
    const [show, setShow] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [localAction, setLocalAction]=useState()
    const [disable, setDisable] = useState(false)
    const [users, setUsers] = useState([])
    const [roles, setRoles] = useState()

    
    useEffect ((e)=>{
        loadItems()
    },[])

    const handleClose = () => setShow(false)
    const handleCloseConfirm = () => setShowConfirm(false)

    function showModifItem(user){
        setDisable(true)
        setLoginItem(user.login)
        setPwdItem(user.pwd)
        setRoleItem(user.role)
        setPrenomItem(user.prenom)
        setNomItem(user.nom)
        setIdItem(user.id)
        setTexteItem(user.nom+" "+user.prenom)
        setLocalAction("modifItem")
        setShow(true)
    }
    function showAddItem(){
        setDisable(true)
        setLocalAction("addItem")
        setShow(true)
    }

    const addItem = async (e) => {
        e.preventDefault()
        setMess1(mess1List[1])
        setMess2(mess2List[1])
        if(loginItem.replace(/ /g, "")==="" || pwdItem.replace(/ /g, "")==="" || prenomItem.replace(/ /g, "")==="" || nomItem.replace(/ /g, "")==="") return false
        const postData = {
            action:"addUser",
            login:loginItem,
            pwd:pwdItem,
            prenom:prenomItem,
            nom:nomItem,
            accessToken: userLocal.accessToken,
            userId: userLocal.idUser
          }
        try {
            const response = await axios.post(APICALL_URL,postData,
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            if(response?.data?.retour){
                loadItems()
                setLoginItem("")
                setPwdItem("")
                setPrenomItem("")
                setNomItem("")
            }
        } catch (err) {

        }
    }

    const modifItem = async (e) => {
        e.preventDefault()
        setMess1(mess1List[1])
        setMess2(mess2List[1])
        if(loginItem.replace(/ /g, "")==="" || pwdItem.replace(/ /g, "")==="" || prenomItem.replace(/ /g, "")==="" || nomItem.replace(/ /g, "")==="") return false
        const postData = {
            action:"modifUser",
            id:idItem,
            login:loginItem,
            pwd:pwdItem,
            prenom:prenomItem,
            nom:nomItem,
            accessToken: userLocal.accessToken,
            userId: userLocal.idUser
          }
        try {
            const response = await axios.post(APICALL_URL,postData,
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            if(response?.data?.retour){
                loadItems()
                setLoginItem("")
                setPwdItem("")
                setPrenomItem("")
                setNomItem("")
            }
        } catch (err) {
            console.log(err)
        }
    }

    const supItem = async (e) => {
        e.preventDefault()
        setShow(false)
        setShowConfirm(true)
    }

    const supItemConfirm = async (e) => {
        e.preventDefault()
        setMess1(mess1List[2])
        setMess2(mess2List[2])
        const postData = {
            action:"supUser",
            id:idItem,
            accessToken: userLocal.accessToken,
            userId: userLocal.idUser
          }
        try {
            const response = await axios.post(APICALL_URL,postData,
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            if(response?.data?.retour){
                setShowConfirm(false)
                loadItems()
            }
        } catch (err) {
            console.log(err)
        }
    }
    const refreshPage=(e) => {
        setMess1(mess1List[0])
        setMess2(mess2List[0])
        loadItems()
    }
    const loadItems = async (e) => {
       // e.preventDefault()
       setSuccess(false)
        const postData = {
          action:"getUsers",
          accessToken: userLocal.accessToken,
          userId: userLocal.idUser
        }
        try {
            const response = await axios.post(APICALL_URL,postData,
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            if(response?.data?.retour){
              setUsers(response?.data?.users)
              setRoles(response?.data?.roles)
           
              setSuccess(true)
              setShow(false)
            }
        } catch (err) {
            setErrMsg(errorAff(err))
        }
      }

      const confirm=<Modal show={showConfirm} onHide={handleCloseConfirm} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirmez la suppression</Modal.Title>
      </Modal.Header>
      <Modal.Body>Voulez-vous vraiment supprimer l'utilisateur <b>{prenomItem + " " + nomItem }</b> ?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseConfirm}>
        <XLg /> Annuler
        </Button>
        <Button variant="primary" onClick={supItemConfirm}>
          <CheckLg/> Oui
        </Button>
      </Modal.Footer>
    </Modal>

    const modal=<Modal show={show} onHide={handleClose} centered>
    <Modal.Header closeButton>
      <Modal.Title>
      { localAction==="addItem" && <span>Ajouter un invité</span> }
      { localAction==="modifItem" && <span>{texteItem}</span> }
        </Modal.Title>
    </Modal.Header>
    <Modal.Body>
        {localAction==="addItem" &&
    <form>
        <label className="w100">Prénom</label>
        <input className="w100"onChange={(e) => {setPrenomItem(e.target.value); setDisable(false)}} type="text" name="nom" required></input>
        <label className="w100 mt-2">Nom</label>
        <input className="w100" onChange={(e) => {setNomItem(e.target.value); setDisable(false)}} type="text" name="nom"></input>
        <label className="w100 mt-2">Login</label>
        <input className="w100" onChange={(e) => {setLoginItem(e.target.value); setDisable(false)}} type="text" name="nom"></input>
        <label className="w100 mt-2">Mot de passe</label>
        <input className="w100 mb-2"  onChange={(e) => {setPwdItem(e.target.value); setDisable(false)}} type="text" name="nom"></input>
    </form>
    }
    {localAction==="modifItem" &&
    <form>
        <label className="w100">Prénom</label>
        <input className="w100" onChange={(e) => {setPrenomItem(e.target.value); setDisable(false)}} type="text" name="nom" value={prenomItem}></input>
        <label className="w100 mt-2">Nom</label>
        <input className="w100" onChange={(e) => {setNomItem(e.target.value); setDisable(false)}} type="text" name="nom" value={nomItem}></input>
        <label className="w100 mt-2">Login</label>
        <input className="w100" onChange={(e) => {setLoginItem(e.target.value); setDisable(false)}} type="text" name="nom" value={loginItem}></input>
        <label className="w100 mt-2">Mot de passe</label>
        <input className="w100 mb-2" onChange={(e) => {setPwdItem(e.target.value); setDisable(false)}} type="text" name="nom" value={pwdItem}></input>
    </form>
    }
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
      <XLg />&nbsp;&nbsp;Annuler
      </Button>
      {localAction==="modifItem" &&
      <>
      {roleItem==="1" &&
        <Button variant="danger" onClick={supItem}>
        <Trash />&nbsp;&nbsp;Supprimer
        </Button>
      }
        <Button variant="primary" onClick={modifItem} disabled={disable}>
            <Save2/>&nbsp;&nbsp;Modifier
        </Button>
     </>
    }
    {localAction==="addItem" &&
        <Button variant="primary" onClick={addItem} disabled={disable}>
           <CheckLg/> Ajouter
        </Button>
    }
    </Modal.Footer>
  </Modal>

    return(
    <div className="container">
      { isOnline ? 
      <>
        { modal }
        { confirm }
        { !success && <p className="mess">{mess1}</p>}
        { success && <p className="mess mess_vert mess_court">{mess2}</p>}
        { errMsg!=="" && <p className="mess alerte">{errMsg}</p>}
        <div className="tar"><span className="picto_ssmenu update sc07" onClick={refreshPage}></span></div>
        <h4>Utilisateurs de l'application</h4>
        <div>
        { success &&
        users?.map(user => <div className="itemParam" key={user.id} onClick={()=>{showModifItem(user)}}>
              <div><b>{user.nom} {user.prenom}</b> &nbsp;&nbsp;&nbsp; <span className="fs08">{roles[user.role].role} / login : {user.login}</span></div>
              </div>)}
              <div className="bloc_plus mt-2" onClick={()=>{showAddItem()}}><span className="picto_admin plus"></span><span className="texte">Ajouter un utilisateur invité</span></div>
              </div>
          </>
      :
      <div>Vous devez être connecté à internet</div> }
    </div>
    )
}

export default Users