import { db } from "../hooks/db"

export function errorAff(err){
    var mess=""
    if (!err?.response) {
        mess='No Server Response';
    } else if (err.response?.status === 400) {
        mess='Missing Username or Password';
    } else if (err.response?.status === 401) {
        mess='Unauthorized';
    } else if (err.response?.status === 404) {
        mess='Page non trouvée';
    } else {
        mess='Erreur '+err.response?.status;
    }
    return mess
}

export function paramMajBddLocale(paramsListe){
    const liste=[]
    paramsListe.forEach((param)=>{
        liste.push({table:param.table,cont:param.cont})
    })
    //console.log(paramsListe)
    liste.forEach((param)=>{
        var table=param.table
        db[table].clear()
        const toPush=[]
        param.cont.forEach((el)=>{
            var nom=el.nom
            var cat=el.cat
            var ordre=el.ordre
            var id=parseInt(el.id)
            if(table==="lieux") {
                toPush.push({id:id,nom:nom})
            } else if(table==="caisses") {
                toPush.push({id:el})
            } else if(table==="categories") {
                toPush.push({id:id,cat:cat,nom:nom,ordre:ordre})
            } else {
                toPush.push({id:id,cat:cat,nom:nom,ordre:ordre})
            }
        })
        db[table].bulkPut(toPush).then(/**/)
    })
    return true
}


export function dateToFr(str){
    let date=str.split('-')
    let sep="-"
    let dateFr=date[2]+sep+date[1]+sep+date[0]
    return (dateFr)
}

export function nl2br (str, replaceMode, isXhtml) {

    var breakTag = '<br />'
    var replaceStr = (replaceMode) ? '$1'+ breakTag : '$1'+ breakTag +'$2';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
  }

  export function convStock(val){
    if(val==="none") val="-"
    return val
  }