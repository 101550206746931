import { useState, useEffect } from 'react'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import {mess1List, mess2List, listeInitiales, APICALL_URL, PDFCALL_URL, listeTypeRech, IMGS_URL, PDFS_URL, filtres1, ordres1 } from '../hooks/data'
import { convStock } from '../hooks/functions'
import { Check } from 'react-bootstrap-icons'
import { Button, Modal } from 'react-bootstrap'
import { XLg, CheckLg, CloudCheck, Trash } from "react-bootstrap-icons"
import Caisses from './Caisses'
import { db } from '../hooks/db'
import CaisseInfos from './CaisseInfos'

function References({page, action, rech, actionChange,  onListChange, getSelection, initSelection, menuAction, setMenuAction, filtres, ordre, isOnline, idCaisse, refs, setRefs, refsAff, setRefsAff}){

    const userLocal = JSON.parse(localStorage.getItem("user"))

    const [caisses, setCaisses] = useState([])
    const [caissesByRef, setCaissesByRef] = useState([])
    const [couvs, setCouvs] = useState([])
    const [success, setSuccess] = useState(false)
    const [randomInt, setRandomInt] = useState()
    const [selectionRefs, setSelectionRefs] = useState([])
    const [clickSelect, setClickSelect] = useState(0)
    const [selectAll, setSelectAll] = useState(false)
    const [nbRefAff, setNbRefAff] = useState(1000)
    const [scrollPosition, setScrollPosition] = useState(0)
    const [nbPages, setNbPages] = useState(1)
    const [contHeight, setContHeight] = useState()
    const [height, setHeight] = useState(0)
    const [prevHeight, setPrevHeight] = useState(0)
    const [showMoveToCaisse, setShowMoveToCaisse] = useState(false)
    const [caisseCible, setCaisseCible] = useState()
    const [listeLieux, setListeLieux] = useState([])
    const [descRech, setDescRech] = useState("")
    const [showExportPdf, setShowExportPdf] = useState(false)
    const [affInfosPdf,setAffInfosPdf] = useState(false)
    const [typePdf,setTypePdf] = useState()
    const [linkPdf, setLinkPdf] = useState("")

    const initiales=listeInitiales[userLocal.idUser]
    const navigate=useNavigate()
    
    useEffect(() => {
        setContHeight(document.getElementById("content").clientHeight)
        const handleResize = () => {
            setContHeight(document.getElementById("content").clientHeight)
        }
        db.lieux.where('id').above(0).sortBy("id").then(
            res=>{
                let tempArray=[]
                res.map(car=>{
                tempArray[car.id]=car
                })
                setListeLieux(tempArray)
        })
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        const handleScroll = () => {
            const position = document.getElementById("content").scrollTop;
            setScrollPosition(position)
            setHeight(document.getElementById("zoneAff").clientHeight)
            if((position>(height-contHeight)) && height!==prevHeight){
                let nbPagesTemp=nbPages
                nbPagesTemp++
                //console.log("next page - - next page - - next page - - next page - - ")
                setRefsAff(refs.slice(0,nbRefAff*nbPagesTemp))
                setNbPages(nbPagesTemp)
                setPrevHeight(height)
            }
        }
        document.getElementById("content").addEventListener('scroll', handleScroll, { passive: true })
    return () => {
        //document.getElementById("content").removeEventListener('scroll', handleScroll)
    }
    }, [scrollPosition])

    useEffect(() => {
        if(initSelection) {
            setSelectionRefs([])
        }
    }, [initSelection])

    useEffect(() => {
        if(refsAff.length>0){
            onListChange(refsAff)
        }
    }, [refsAff])

    useEffect(() => {
        if(selectAll){
            let listeRefsSelect=[]
            refsAff.map(ref=>{
                listeRefsSelect.push(ref.id)
            })
            setSelectionRefs(listeRefsSelect)
        } else {
            setSelectionRefs([])
        }
    }, [selectAll])

    useEffect(() => {
        if(action==="select-true"){
            let listeRefsSelect=selectionRefs
            var index = listeRefsSelect.indexOf(clickSelect);
            if (index !== -1) {
                listeRefsSelect.splice(index, 1);
            } else if(clickSelect!==0) {
                listeRefsSelect.push(clickSelect)
            }
            setSelectionRefs(listeRefsSelect)
            setClickSelect(0)
            getSelection(listeRefsSelect)
        } else if(clickSelect!==0) {
            actionChange("viewRef",clickSelect)
            setClickSelect(0)
        }
    }, [clickSelect])

    useEffect(() => {
        //console.log(selectionRefs)
        if(menuAction==="corbeille" || menuAction==="outCorbeille" || menuAction==="deleteRef"){
            let act=""
            if(menuAction==="corbeille") act="refsToCorbeille"
            if(menuAction==="outCorbeille") act="refsOutCorbeille"
            if(menuAction==="deleteRef") act="deleteRef"
            const postData = {
                action:act,
                references:selectionRefs,
                filtres:filtres,
                ordre:ordre,
                accessToken: userLocal.accessToken,
                userId: userLocal.idUser
              }
            axios.post(APICALL_URL,postData,
              {
                  headers: { 'Content-Type': 'application/json' },
                  withCredentials: true
              })
              .then(res => {
                setRefs(res.data.liste)
                setRefsAff(res.data.liste.slice(0,nbRefAff*nbPages))
                setCouvs(res.data.couvs)
                setSuccess(true)
                setMenuAction("")
              })
        } else if(menuAction==="moveToCaisse"){
            setCaisseCible("")
            setShowMoveToCaisse(true)
        } else if(menuAction==="exportPDF" || menuAction==="exportPDFEtiquettes"){
            if(menuAction==="exportPDF") setTypePdf(1);
            if(menuAction==="exportPDFEtiquettes") setTypePdf(2);
            setShowExportPdf(true)
        }
    },[menuAction])

    useEffect(() => {
        setSelectionRefs([])
        document.getElementById("content").scrollTop=0
        if(filtres && ordre) {
            loadRefs()
        }
    },[filtres,ordre,isOnline])

    useEffect(() => {
        if(showExportPdf){
            setAffInfosPdf(false)
        }
    },[showExportPdf])

    function toggleSelectAll(){
        setSelectAll(!selectAll)
    }

    function exportPDF(){
        setAffInfosPdf(false)
        let act=""
        if(typePdf===1) act="export_ref"
        if(typePdf===2) act="export_liste"
        const postData = {
            action:act,
            references:selectionRefs,
            accessToken: userLocal.accessToken,
            userId: userLocal.idUser
            }
        axios.post(PDFCALL_URL,postData,
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            })
            .then(res => {
            let url=PDFS_URL+res.data+".pdf"
            setLinkPdf(url)
            setAffInfosPdf(true)
            setSuccess(true)
            setMenuAction("")
            })
    }

    function loadRefs(){
        if(isOnline){
            setSuccess(false)
            let filtresLoad=filtres
            let rech={}
            if(page==="references-validees") filtresLoad=filtres1[2].val
            if(page==="caisse") filtresLoad=[{champ:"stock_type",op:"=",val:2,type:"int"},{champ:"stock_caisse",op:"=",val:idCaisse,type:"int"}]
            if(page==="archives") filtresLoad=[{champ:"statut",op:"=",val:2,type:"int"}]
            const postData = {
                action:"getReferences",
                filtres:filtresLoad,
                ordre:ordre,
                accessToken: userLocal.accessToken,
                userId: userLocal.idUser
            }
            axios.post(APICALL_URL,postData,
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            })
            .then(res => {
                if(!res.data.tokenAuth) navigate("/login")
                setDescRech(res.data.descRech)
                setCaisses(res.data.caisses)
                setCaissesByRef(res.data.caissesByRef)
                let liste=res.data.liste
                setRefs(liste)
                setRefsAff(liste.slice(0,nbRefAff*nbPages))
                setCouvs(res.data.couvs)
                setRandomInt(Math.floor(Math.random() * 100000))
                setSuccess(true)
            })

        }
    }

    function getStatutValidation(data){
        let validation=JSON.parse(data)
        let eb=validation.eb
        let rb=validation.rb
        let statut="pictoZone statutValideConflit"
        if(eb===rb) {
            if(eb===1) statut="pictoZone statutValideOk"
            else if(eb===2) statut="pictoZone statutValideNo"
        }
        return statut
    }

    function moveToCaisse(){
        const postData = {
            action:"moveToCaisse",
            caisse:caisseCible,
            references:selectionRefs,
            accessToken: userLocal.accessToken,
            userId: userLocal.idUser
        }
        axios.post(APICALL_URL,postData,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        })
        .then(res => {
            setShowMoveToCaisse(false)
            loadRefs()
        })
    }

    function hideMoveToCaisse(){
        setShowMoveToCaisse(false)
        setMenuAction("")
    }

    function hideExportPdf(){
        setShowExportPdf(false)
        setMenuAction("")
    }

    const modalMoveToCaisse=<Modal show={showMoveToCaisse} onHide={hideMoveToCaisse} centered>
    <Modal.Header closeButton>
    <Modal.Title>
        Déplacer les références dans une caisse
        </Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <div className='mb-2'><b>Choisir une caisse</b></div>
    <select onChange={e=>setCaisseCible(e.target.value)} value={caisseCible}>
        <option value="">Choix…</option>
        {caisses &&
        caisses.map((caisse,id)=>
        caisse.id!==idCaisse &&<option key={id} value={caisse.id}>C{caisse.id}</option>
        )}
    </select>
    </Modal.Body>
    <Modal.Footer>
    <Button variant="secondary" onClick={hideMoveToCaisse}>
    <XLg /> Annuler
    </Button>
    <Button disabled={caisseCible==="" && "disabled"} variant="primary" onClick={moveToCaisse}>
        <CheckLg/> Déplacer
    </Button>
    </Modal.Footer>
    </Modal>

    const modalExportPdf=
    <Modal show={showExportPdf} onHide={hideExportPdf} centered>
    <Modal.Header closeButton>
    <Modal.Title className='w100'>
        { typePdf===1 && <>Exporter les fiches en PDF</> }
        { typePdf===2 && <>Générer les étiquettes</> }
        </Modal.Title>
    </Modal.Header>
    {affInfosPdf &&
    <Modal.Body>
    <div>
        <b>Votre document est prêt !</b><br/>
        <a href={linkPdf} target="_blank">Télécharger le PDF</a>
    </div>
    </Modal.Body>
    }
    <Modal.Footer>
    <Button variant="secondary" onClick={hideExportPdf}>
        <XLg /> Annuler
    </Button>
    <Button variant="primary" onClick={exportPDF}>
        <CheckLg/> Exporter
    </Button>
    </Modal.Footer>
    </Modal>

    return(
        <>
        {rech &&
            <div className='container arianeRech'>
                <span>{descRech} : {refs.length} résultat{refs.length>1 && "s"}</span>
            </div>
        }
        <div className='container' id='zoneAff'>
            {modalMoveToCaisse}
            {modalExportPdf}
            {isOnline ?
            <>
            {page==="caisse" &&
                <CaisseInfos refId={idCaisse} edit={true} refsAff={refsAff} />
            }
            {action==="select-true" &&
            <div className='row'><div className='col-12'><span className='btn1 mr-3' onClick={toggleSelectAll}><Check/>{!selectAll && "Tout sélectionner"}{selectAll && "Tout déselectionner"}</span></div></div>
            }
            <div className='row gx-2 gx-md-3'>
                {refsAff?.map((ref)=>(
                    <div onClick={()=>setClickSelect(ref.id)} key={ref.id} className='col-4 col-md-3 mb-2 mb-md-4'>
                        <div className='bloc-ref'>
                            <div className='bloc-ref-cont'>
                                <div className='bloc-ref-visu'
                                style={
                                    couvs[ref.id] &&
                                    {backgroundImage:"url("+IMGS_URL+ref.id+"/md/"+couvs[ref.id]["nom"]+"."+couvs[ref.id]["extension"]+"?"+randomInt+")"}
                                    }
                                    >
                                        <div className="infosRef">
                                            <span className="ref-show">{ref.id}</span>
                                            {((ref.stock_type==="1" || ref.stock_type==="2") && (userLocal.roles>=2001 && page!=="archives")) &&
                                            <span className="loc-show">
                                                {ref.stock_type==="1" ?
                                                <>
                                                {ref.lieu!="0" && listeLieux[ref.lieu]?.nom.substring(0,1)} | {convStock(ref.zone)} | {convStock(ref.rangee)} | {convStock(ref.niveau)}
                                                </>
                                                :
                                                <>
                                                {ref.stock_caisse!="0"
                                                    ?
                                                    <>
                                                    {caissesByRef[ref.stock_caisse]?.lieu!=="0"
                                                    ? <>{listeLieux[caissesByRef[ref.stock_caisse]?.lieu]?.nom.substring(0,1)} </>
                                                    : <>ND </>}
                                                    | <>C{ref.stock_caisse}</>
                                                    </>
                                                    :
                                                    <>ND</>
                                                }
                                                </>
                                                }
                                            </span>
                                            }
                                        </div>
                                </div>         
                            </div>
                            { (page==="references" || page==="caisse") &&
                            <>
                            {userLocal.roles>=4001 &&
                            <>
                            {ref.statut==="0" && <div className="pictoZone valideNot"></div> }
                            {ref.statut==="1" && <div className="pictoZone valideYes"></div> }
                            {ref.statut==="3" && <div className="pictoZone corbeille"></div> }
                            </>
                            }
                            {(userLocal.roles===2001 && (userLocal.idUser==="22" || userLocal.idUser==="23")) &&
                            <>
                            {(ref.statut==="0" && JSON.parse(ref.validations)[initiales]===0) && <div className="picto_icone spec1 attente"></div> }
                            {((ref.statut==="0" || ref.statut==="1") && JSON.parse(ref.validations)[initiales]===1) && <div className="picto_icone spec1 oui"></div> }
                            {((ref.statut==="0" || ref.statut==="1") && JSON.parse(ref.validations)[initiales]===2) && <div className="picto_icone spec1 non"></div> }
                            {((ref.statut==="0" || ref.statut==="1") && JSON.parse(ref.validations)[initiales]===3) && <div className="picto_icone spec1 rem"></div> }
                            </>
                            }
                            </>
                            }
                            { page==="references-validees" &&
                            <>
                            <div className={getStatutValidation(ref.validations)}></div>
                            </>
                            }
                            <div
                            className=
                            {(action==="select-true" && selectionRefs.includes(ref.id)) ? "selectZone" : "selectZone d-none"}
                            ></div>
                        </div>
                    </div>
                ))}
            </div>
            </>
            :
            <div>Vous devez être connecté à internet</div>
            }
        </div>
    </>
    )
}

export default References