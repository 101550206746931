import Layout from '../components/Layout';

function NotFound({page}){
    return(
        <div className='container' id='zoneAff'>
            Cette page n'existe pas
        </div>
    )
}

export default NotFound