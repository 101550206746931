import { useState, useEffect } from "react"
import axios from "axios"
import {APICALL_URL, auteurs, listeTypeRech, phases} from '../hooks/data'


function Recherche({type, setViewOver, getFiltres, setRech}){

    const userLocal = JSON.parse(localStorage.getItem("user"))
    const [categories, setCategories] = useState([])
    const [familles, setFamilles] = useState([])
    const [editeurs, setEditeurs] = useState([])
    const [lieux, setLieux] = useState([])
    const [typologies, setTypologies] = useState([])
    const [caracteristiques, setCaracteristiques] = useState([])

    const [categorie, setCategorie] = useState('')
    const [famille, setFamille] = useState('')
    const [typologie, setTypologie] = useState('')
    const [caracTexte, setCaracTexte] = useState('')
    const [phase, setPhase] = useState('')
    const [auteur, setAuteur] = useState('')
    const [editeur, setEditeur] = useState('')
    const [indexation, setIndexation] = useState('')
    const [lieu, setLieu] = useState('')
    const [id, setId] = useState('')
    const [loaded, setLoaded] = useState(false)
    const [typeRech, setTypeRech] = useState("0")


    useEffect(()=>{
        localStorage.getItem("typeRech") && setTypeRech(localStorage.getItem("typeRech"))
        loadParams()
    },[type])

    useEffect(()=>{
        resetForm("cat")
    },[categorie])

    useEffect(()=>{
        if(loaded) resetForm()
    },[typeRech])

    function loadParams(){
        setLoaded(false)
        const postData = {
            action:"getParametresOnly",
            accessToken: userLocal.accessToken,
            userId: userLocal.idUser
        }
        axios.post(APICALL_URL,postData,
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            })
            .then(res => {
                console.log(res)
                setCategories(res.data.categories)
                setFamilles(res.data.familles)
                setTypologies(res.data.typologies)
                setEditeurs(res.data.editeurs)
                setLieux(res.data.lieux)
                setCaracteristiques(res.data.caracteristiques)
                setLoaded(preRemp())
        })
    }

    function preRemp(){
    let rechLocal=localStorage.getItem("rech");
        if(rechLocal){
            rechLocal=JSON.parse(rechLocal)
            rechLocal.forEach(filtre=>{
                filtre.champ==="categorie" && setCategorie(filtre.val)
                filtre.champ==="famille" && setFamille(filtre.val)
                filtre.champ==="editeur" && setEditeur(filtre.val)
                filtre.champ==="typologie" && setTypologie(filtre.val)
                filtre.champ==="auteur" && setAuteur(filtre.val)
                filtre.champ==="phase" && setPhase(filtre.val)
                filtre.champ==="id" && setId(filtre.val)
                filtre.champ==="caracteristiques" && setCaracTexte(filtre.val)
                filtre.champ==="indexation" && setIndexation(filtre.val)
            })
            return (true)
        } else {
            return (true)
        }
    }

    function resetForm(except){
        //localStorage.setItem("rech", "")
        except!=="cat" && setCategorie("")
        setFamille("")
        setEditeur("")
        setTypologie("")
        except!=="cat" && setAuteur("")
        except!=="cat" && setPhase("")
        setId("")
        setCaracTexte("")
        setIndexation("")
    }

    function goSearch(){
        setViewOver(false)
        let rech=[
            {champ:"categorie",op:"=",val:categorie,type:"int"},
            {champ:"famille",op:"=",val:famille,type:"int"},
            {champ:"editeur",op:"=",val:editeur,type:"int"},
            {champ:"typologie",op:"=",val:typologie,type:"int"},
            {champ:"auteur",op:"=",val:auteur,type:"int"},
            {champ:"phase",op:"=",val:phase,type:"int"},
            {champ:"id",op:"=",val:id,type:"int"},
            {champ:"caracteristiques",op:"LIKE",val:caracTexte,type:"string"},
            {champ:"indexation",op:"LIKE",val:indexation,type:"string"}
        ]
        let filtres=[]
        rech.forEach(filtre=>{
            if(filtre.val!="") filtres.push(filtre)
        })
        if(filtres.length===0) filtres.push({champ:"id",op:">",val:0,type:"int"})
        setRech(true)
        getFiltres(filtres)
        localStorage.setItem("rech", JSON.stringify(rech))
        localStorage.setItem("typeRech", typeRech)
    }
    return(
        <div className="container fiche ficheRech">
        <div className="formRech-header">
            <select className="form-select" value={typeRech} onChange={e=>setTypeRech(e.target.value)}>
            {listeTypeRech.map(type=>
                <option key={type.id} value={type.id}>{type.val}</option>
                )}
            </select>
        </div>
        <div>
            {typeRech==="0" &&
            <div className="row formRech">
                <div className="col-md-3">
                    <label>Catégorie</label>
                </div>
                <div className="col-md-9 mb-3">
                    <select value={categorie} onChange={e=>setCategorie(e.target.value)} className="form-select form-select-sm">
                        <option value="">Choisir…</option>
                        {categories?.map((el,i)=>
                        <option key={i+el.nom} value={el.id}>{el.nom}</option>
                        )}
                    </select>
                </div>
                <div className="col-md-3">
                    <label>Famille</label>
                </div>
                <div className="col-md-9 mb-3">
                    <select value={famille} onChange={e=>setFamille(e.target.value)} className="form-select form-select-sm">
                        <option value="">Choisir…</option>
                        {familles?.map((el,i)=>
                        el.cat===categorie && <option key={i} value={el.id}>{el.nom}</option>
                        )}
                    </select>
                </div>
                <div className="col-md-3">
                    <label>Typologie</label>
                </div>
                <div className="col-md-9 mb-3">
                    <select value={typologie} onChange={e=>setTypologie(e.target.value)} className="form-select form-select-sm">
                        <option value="">Choisir…</option>
                        {typologies?.map((el,i)=>
                        el.cat===categorie && <option key={i} value={el.id}>{el.nom}</option>
                        )}
                    </select>
                </div>
                <div className="col-md-3">
                    <label>Éditeur</label>
                </div>
                <div className="col-md-9 mb-3">
                    <select value={editeur} onChange={e=>setEditeur(e.target.value)} className="form-select form-select-sm">
                        <option value="">Choisir…</option>
                        {editeurs?.map((el,i)=>
                        el.cat===categorie && <option key={i} value={el.id}>{el.nom}</option>
                        )}
                    </select>
                </div>
                <div className="col-md-3 mt-4">
                    <label>Phase</label>
                </div>
                <div className="col-md-9 mb-3 mt-4">
                    <select value={phase} onChange={e=>setPhase(e.target.value)} className="form-select form-select-sm">
                        <option value="">Choisir…</option>
                        {phases?.map((el,i)=>
                        <option key={i} value={el.id}>{el.nom}</option>
                        )}
                    </select>
                </div>
                <div className="col-md-3">
                    <label>Auteur</label>
                </div>
                <div className="col-md-9 mb-3">
                    <select value={auteur} onChange={e=>setAuteur(e.target.value)} className="form-select form-select-sm">
                        <option value="">Choisir…</option>
                        {auteurs?.map((el,i)=>
                        <option key={i} value={el.id}>{el.nom}</option>
                        )}
                    </select>
                </div>
            </div>
            }
            {typeRech==="1" &&
            <div className="row formRech">
                <div className="col-12">
                    <label>Caractéristique et description</label>
                </div>
                <div className="col-12">
                    <input placeholder="un ou plusieurs mots séparés par une virgule" type="text" onChange={e=>setCaracTexte(e.target.value)} value={caracTexte} className="form-control form-control-sm"/>
                </div>
            </div>
            }
            {typeRech==="2" &&
            <div className="row formRech">
                <div className="col-md-3">
                    <label>Référence</label>
                </div>
                <div className="col-md-9 mb-2">
                    <input type="number" onChange={e=>setId(e.target.value)} value={id} className="form-control form-control-sm"/>
                </div>
            </div>
            }
            {typeRech==="3" &&
            <div className="row formRech">
                <div className="col-12">
                    <label>Recherche globale dans l'indexation</label>
                </div>
                <div className="col-12">
                    <input placeholder="un ou plusieurs mots séparés par une virgule" type="text" onChange={e=>setIndexation(e.target.value)} value={indexation} className="form-control form-control-sm"/>
                </div>
            </div>
            }
            {loaded &&
            <div className="tac" onClick={goSearch}><span className="picto_ssmenu search"></span></div>
            }
        </div>
        </div>
    )
}

export default Recherche