import { useRef, useState } from 'react'
import useAuth from '../hooks/useAuth'
import axios from 'axios'
import jwt_decode from "jwt-decode"
import { Link, useLocation } from 'react-router-dom'
import { paramMajBddLocale } from '../hooks/functions'
import { ChevronRight } from "react-bootstrap-icons"
import {APICALL_URL} from '../hooks/data'

const LOGIN_URL=process.env.REACT_APP_API_ROOT+"/login_v1.php"

function Login(){
    const location = useLocation()
    const from = location.state?.from?.pathname || "/"

  const { setAuth } = useAuth()
    const userRef = useRef()
    const errRef = useRef()
    const [user, setUser] = useState('')
    const [pwd, setPwd] = useState('')
    const [roles, setRoles] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const [affEnter, setAffEnter] = useState(false)

    const handleSubmit = async (e) => {
      e.preventDefault();

      const postData = {
        action:"login",
        login: user,
        pwd: pwd
      }

      try {
          const response = await axios.post(LOGIN_URL,postData,
              {
                  headers: { 'Content-Type': 'application/json' },
                  withCredentials: true
              }
          );
          if(response?.data?.accessToken){
            const accessToken = response?.data?.accessToken;
            let rolesOk = response?.data?.roles;
            setRoles(rolesOk)
            setAuth({ user, rolesOk, accessToken });
            setUser('');
            setPwd('');
            
            //var decoded = jwt_decode(accessToken);
            localStorage.setItem("user", JSON.stringify(response.data));
            let userLocal = response.data
            //navigate(from, { replace: true });
            // Chargement bdd locale
            const postData = {
                action:"getParametres",
                accessToken: userLocal.accessToken,
                userId: userLocal.idUser
            }
            axios.post(APICALL_URL,postData,
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                })
                .then(res => {
                    console.log(res)
                // Maj Bdd locale
                paramMajBddLocale(res.data.bdd) // enregistrement en local
                setAffEnter(true)
                console.log(userLocal)
            })
          }else{
            setErrMsg('Utilisateur non reconnu');
          }
      } catch (err) {
          if (!err?.response) {
              setErrMsg('No Server Response');
          } else if (err.response?.status === 400) {
              setErrMsg('Missing Username or Password');
          } else if (err.response?.status === 401) {
              setErrMsg('Unauthorized');
          } else {
              setErrMsg('Login Failed');
          }
          errRef.current.focus();
      }
    }
      
     
        return (
          <div className='container h100'>
            <section className='box h100'>
                {affEnter ? 
                    roles===1001 ?
                    <Link to="/archives"><button className='btn btn-light btn-lg'>Bienvenue <ChevronRight/></button></Link>
                    :
                    <Link to="/references"><button className='btn btn-light btn-lg'>Bienvenue <ChevronRight/></button></Link>
                :
                    <form onSubmit={handleSubmit}>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                        <input
                            className='text-lowercase'
                            type="text"
                            id="username"
                            ref={userRef}
                            autoComplete="off"
                            onChange={(e) => setUser(e.target.value)}
                            value={user}
                            placeholder="Login"
                            required
                        /><br/>
                        <input
                            className='mgt1'
                            type="password"
                            id="password"
                            onChange={(e) => setPwd(e.target.value)}
                            value={pwd}
                            placeholder="Mot de passe"
                            required
                        /><br/>
                        <button className='btn btn-light mgt1 w100'>ENTRER</button>
                    </form>
                }
            </section>
          </div>
        )
}

export default Login