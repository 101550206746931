import {useParams} from 'react-router-dom'
import Layout from '../components/Layout'

function CaisseEdit({page}){
    const { refId } = useParams();
    return(
        <Layout page={page} refId={refId} />
    )
}

export default CaisseEdit