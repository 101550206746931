import { useState, useEffect } from "react"
import axios from 'axios'
import { APICALL_URL, PDFCALL_URL, IMGS_URL, DOCS_URL, PDFS_URL, listeInitiales, listeAuteurs, listePhases, zones, rangees, niveaux } from '../hooks/data'
import { convStock } from "../hooks/functions"
import { Link, useNavigate } from 'react-router-dom'
import { db } from "../hooks/db"
import { useLiveQuery } from "dexie-react-hooks"
import { Carousel, Button, Modal } from "react-bootstrap"
import { XLg, CheckLg, CloudCheck, Trash } from "react-bootstrap-icons"

function ReferenceFiche({page, validations, setValidations,  setStatutObj, getStatut, statutValidation, setStatutValidation, setShowValidation, refId, actionChange, isOnline, local, newMess}){

    const userLocal = JSON.parse(localStorage.getItem("user"))
    const [objet,setObjet] = useState()
    const [statut,setStatut] = useState()
    const [messages,setMessages] = useState([])
    const [photos,setPhotos] = useState([])
    const [docs, setDocs] = useState([])
    const [categorie,setCategorie] = useState(0)
    const [famille,setFamille] = useState(0)
    const [typologie,setTypologie] = useState(0)
    const [phase,setPhase] = useState([])
    const [editeur,setEditeur] = useState(0)
    const [auteur, setAuteur] = useState('none')
    const [description, setDescription] = useState("")
    const [caracteristiques, setCaracteristiques]= useState([])
    const [lieu, setLieu] = useState("none")
    const [infos, setInfos] = useState("")
    const [typeStockage,setTypeStockage] = useState("none")
    const [infoStockage,setInfoStockage] = useState("")
    const [zone, setZone] = useState("none")
    const [rangee, setRangee] = useState("none")
    const [niveau, setNiveau] = useState("none")
    const [caisse, setCaisse] = useState("none")
    const [listeCaracs, setListeCaracs] = useState([])
    const [listeLieux, setListeLieux] = useState([])
    const [listeStockageTypes, setListeStockageTypes] = useState([])
    const [listeCategories, setListeCategories] = useState([])
    const [listeFamilles, setListeFamilles] = useState([])
    const [listeTypologies, setListeTypologies] = useState([])
    const [listeEditeurs, setListeEditeurs] = useState([])
    const [success,setSuccess] = useState(false)
    const [randomInt, setRandomInt] = useState()
    const [objetLoaded,setObjetLoaded] = useState(false)
    const [categoriesLoaded, setCategoriesLoaded] = useState(false)
    const [famillesLoaded, setFamillesLoaded] = useState(false)
    const [typologiesLoaded, setTypologiesLoaded] = useState(false)
    const [editeursLoaded, setEditeursLoaded] = useState(false)
    const [caracLoaded, setCaracLoaded] = useState(false)
    const [lieuxLoaded, setLieuxLoaded] = useState(false)
    const [stockageTypesLoaded, setStockageTypesLoaded] = useState(false)
    const [referenceId, setReferenceId] = useState(refId)
    const [affInfosPdf,setAffInfosPdf] = useState(false)
    const [linkPdf, setLinkPdf] = useState("")
    const [showExportPdf, setShowExportPdf] = useState(false)

    const initiales=listeInitiales[userLocal.idUser]
    const navigate = useNavigate()


      const loadLocalObjet = useLiveQuery(
        () => db.objets.where('id').equals(parseInt(referenceId)).sortBy("id").then(
          res=>{
            if(local){
              let objet=res[0]
              setCategorie(objet.categorie)
              setFamille(objet.famille)
              setTypologie(objet.typologie)
              setPhase(objet.phase)
              setAuteur(objet.auteur)
              setEditeur(objet.editeur)
              setDescription(objet.description)
              setCaracteristiques(objet.caracteristiques)
              setTypeStockage(objet.stock_type)
              setCaisse(objet.stock_caisse)
              setObjetLoaded(true)
            }
          }
          )
      )

      const loadCategories = useLiveQuery(
        () => db.categories.where('id').above(0).sortBy("id").then(
          res=>{
            let tempArray=[]
            res.map(car=>{
              tempArray[car.id]=car
            })
            setListeCategories(tempArray)
            setCategoriesLoaded(true)
          }
          )
      )

      const loadFamilles = useLiveQuery(
        () => db.familles.where('id').above(0).sortBy("id").then(
          res=>{
            let tempArray=[]
            res.map(car=>{
              tempArray[car.id]=car
            })
            setListeFamilles(tempArray)
            setFamillesLoaded(true)
          }
          )
      )

      const loadTypologies = useLiveQuery(
        () => db.typologies.where('id').above(0).sortBy("id").then(
          res=>{
            let tempArray=[]
            res.map(car=>{
              tempArray[car.id]=car
            })
            setListeTypologies(tempArray)
            setTypologiesLoaded(true)
          }
          )
      )

      const loadCaracs = useLiveQuery(
        () => db.caracteristiques.where('id').above(0).sortBy("id").then(
          res=>{
            let tempArray=[]
            res.map(car=>{
              tempArray[car.id]=car
            })
            setListeCaracs(tempArray)
            setCaracLoaded(true)
          }
          )
      )

      const loadEditeurs = useLiveQuery(
        () => db.editeurs.where('id').above(0).sortBy("id").then(
          res=>{
            let tempArray=[]
            res.map(car=>{
              tempArray[car.id]=car
            })
            setListeEditeurs(tempArray)
            setEditeursLoaded(true)
          }
          )
      )

      const loadLieux = useLiveQuery(
        () => db.lieux.where('id').above(0).sortBy("id").then(
          res=>{
            let tempArray=[]
            res.map(car=>{
              tempArray[car.id]=car
            })
            setListeLieux(tempArray)
            setLieuxLoaded(true)
          }
          )
      )
  
      const loadStockageTypes = useLiveQuery(
        () => db.stockage_types.where('id').above(0).sortBy("id").then(
          res=>{
            let tempArray=[]
            res.map(car=>{
              tempArray[car.id]=car
            })
            setListeStockageTypes(tempArray)
            setStockageTypesLoaded(true)
          }
          )
      )
      const loadLocalPhotos = useLiveQuery(
        () => db.medias.where({id_ref:parseInt(referenceId),type:'photo'}).sortBy("id").then(
          res=>{
            if(local){
              setPhotos(res)
            }
          }
          )
      )
  
      const loadLocalDocs = useLiveQuery(
        () => db.medias.where({id_ref:parseInt(referenceId),type:'doc'}).sortBy("id").then(
          res=>{
            if(local){
              setDocs(res)
            }
          }
          )
      )
    

    useEffect(() => {
        if(!local && isOnline){
            loadRef(referenceId)
          } else {
            setSuccess(true)
          }
    },[])

    useEffect(() => {
        if(!local){
            setReferenceId(refId)
            loadRef(refId)
        }
    },[refId])

    useEffect(() => {
      console.log(newMess)
      if (typeof newMess !== 'undefined') loadRef(refId)
      /*let messagesTemp=messages
      messagesTemp.push(newMess)
      setMessages(messagesTemp)*/
  },[newMess])

    useEffect(() => {
      if(showExportPdf){
          setAffInfosPdf(false)
      }
  },[showExportPdf])

    function loadRef(ref){
        setSuccess(false)
        const postData = {
            action:"getReference",
            refId:ref,
            accessToken: userLocal.accessToken,
            userId: userLocal.idUser
        }
        axios.post(APICALL_URL,postData,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        })
        .then(res => {
          if(!res.data.tokenAuth) navigate("/login")
            setObjet(res.data.objet)
            console.log(res.data.messages)
            setMessages(res.data.messages)
            setStatut(res.data.objet.statut)
            page!=="reference" && setStatutObj(res.data.objet.statut)
            setPhotos(res.data.photos)
            setDocs(res.data.docs)
            setCategorie(res.data.categorie)
            setPhase(res.data.phase)
            setFamille(res.data.famille)
            setTypologie(res.data.typologie)
            setEditeur(res.data.editeur)
            setAuteur(res.data.objet.auteur)
            setCaracteristiques(JSON.parse(res.data.objet.caracteristiques))
            setDescription(res.data.objet.description)
            let validationsData=JSON.parse(res.data.objet.validations)
            setValidations(validationsData)
            setStatutValidation(validationsData[initiales])
            let tempArray=[]
            res.data.caracteristiques.map(car=>{
              tempArray[car.id]=car
            })
            setListeCaracs(tempArray)
            //setRandomInt(Math.floor(Math.random() * 100000))
            setSuccess(true)
          
        })
    }

    function GetValidation({data}){
      let classe="picto_icone sc08 d-inline-block "
      let ajout=""
      if(data===1) {
        ajout="oui"
      } else if(data===2){
        ajout="non"
      } else if(data===3){
        ajout="rem"
      } else {
        ajout="attente"
      }
      return <span className={classe+ajout}></span>
  }

  function exportPDF(){
    setAffInfosPdf(false)
    let act=""
    act="export_ref"
    const postData = {
        action:act,
        referenceId:refId,
        accessToken: userLocal.accessToken,
        userId: userLocal.idUser
      }
    axios.post(PDFCALL_URL,postData,
      {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      })
      .then(res => {
        let url=PDFS_URL+res.data+".pdf"
        setLinkPdf(url)
        setAffInfosPdf(true)
        setSuccess(true)
      })
  }
  
  function hideExportPdf(){
    setShowExportPdf(false)
}

const modalExportPdf=
  <Modal show={showExportPdf} onHide={hideExportPdf} centered>
  <Modal.Header closeButton>
  <Modal.Title className='w100'>
      Exporter la fiche en PDF
      </Modal.Title>
  </Modal.Header>
  {affInfosPdf &&
  <Modal.Body>
  <div>
      <b>Votre document est prêt !</b><br/>
      <a href={linkPdf} target="_blank">Télécharger le PDF</a>
  </div>
  </Modal.Body>
  }
  <Modal.Footer>
  <Button variant="secondary" onClick={hideExportPdf}>
      <XLg /> Annuler
  </Button>
  <Button variant="primary" onClick={exportPDF}>
      <CheckLg/> Exporter
  </Button>
  </Modal.Footer>
  </Modal>

    return(
    <div className="container fiche">
      {modalExportPdf}
        {success &&
        <div className="row h100 typo1 coul1 ficheCont">
            <div className="col-md-7 visuels">
                <Carousel interval={null} controls={photos.length>1 ? true : false}>
                  {photos?.map((photo,index)=>
                    <Carousel.Item key={index}>
                      <div className="diapoItem"
                      style={!local
                        ? {backgroundImage:"url("+IMGS_URL+referenceId+"/md/"+photo["nom"]+"."+photo["extension"]+")"}
                        : {backgroundImage:"url("+photo.file+")"}
                    }
                      >
                        <Carousel.Caption>
                          {!local &&
                        <a target="_blank" href={IMGS_URL+referenceId+"/"+photo["nom"]+"."+photo["extension"]} className="picto_admin downloadCircle spec1"></a>
                          }
                        </Carousel.Caption>
                      </div>
                  </Carousel.Item>
                  )}
                </Carousel>
            </div>
            <div className="col-md-5 texte coul1">
              <div className="fiche-auteur">{!local && categorie}{local && listeCategories[categorie]?.nom}{(auteur!=="none" && auteur!=="0") && " – "+listeAuteurs[auteur]}</div>
              <h4 className="coul1">
                  <b>{local && listeFamilles[famille]?.nom}{!local && famille}
                  {(typologie!=="" && typologie!==null && typologie!=="none" && !local) && " / "+typologie}
                  {(typologie!=="" && typologie!==null && typologie!=="none" && local) && "/ "+listeTypologies[typologie]?.nom}</b>
              </h4>
              <p>{local && listePhases[phase]}{!local && phase}</p>
              <div className="caracteristiques">
                {caracteristiques.map((car,i)=>
                  <div className="caracteristique upFirst" key={i}>{listeCaracs[car.id]?.nom} <b>{car?.cont}</b></div>
                )}
                {(!local && editeur) && <div className="caracteristique">Éditeur <b>{editeur}</b></div>}
                {(local && editeur!=="none") && <div className="caracteristique">Éditeur <b>{listeEditeurs[editeur]?.nom}</b></div>}
                </div>
                {description!=="" &&
                <div className="infos mt-4">{description}</div>
                }
                <div className="docs fs08">
                  {docs?.map((doc,index)=>
                    <a key={index} href={!local ? DOCS_URL+referenceId+"/"+doc.nom : "#"}>
                      <div>
                        {!local && <span className="picto_admin download spec"></span>}
                        <span className="extension"><b>
                        {(() => {
                          let ext=doc.extension
                          if (ext.includes("video")) {return ("video ") }
                          else if (ext.includes("zip")) {return ("zip ") }
                          else if (ext.includes("image")) {return ("image ") }
                          else if (ext.includes("pdf")) {return ("pdf ") }
                          else if (ext.includes("excel")) {return ("excel ") }
                          else if (ext) {return (ext.split("/")[1]+" ") }
                          else {return ("autre  ") }
                        })()}
                        </b></span>
                          {doc.titre.length<=25 ? doc.titre : doc.titre.substring(0,25)+"..."}
                      </div>
                    </a>
                )}
                </div>
                <p onClick={()=>setShowExportPdf(true)} className="picto_admin exportpdf spec1 align-baseline"></p>
                {messages.length>0 &&
                <div className="message">
                {messages.map(mess=>
                  <div className="fs08 mb-1" key={mess.id+mess.cont}><b>• {mess.prenom}, le {mess.date} :<br/>{mess.cont}</b></div>
                  )}
                </div>
                }
            </div>
        </div>
        }
        {(userLocal.roles>=2001 && page!=="archives")
          ?
          <div className="admin-links">
            <div className="infos">
              Réf.<b>{refId}</b>
              {(!local && success) &&
              <>
              {(objet.stock_type==="1" || objet.stock_type==="2") &&
                  objet.stock_type==="1" ?
                  <>&nbsp;–&nbsp;
                  {objet.lieu!="0" && listeLieux[objet.lieu].nom.substring(0,1)} | {convStock(objet.zone)} | {convStock(objet.rangee)} | {convStock(objet.niveau)}
                  </>
                  :
                  <>
                  {objet.stock_type==="2" &&
                    <>&nbsp;–&nbsp;
                    caisse C{objet.stock_caisse}
                    </>
                  }
                  </>
              }
              </>
              }
            </div>
            {((statut==="0" || statut==="1") && page!=="reference") &&
            <div onClick={e=>setShowValidation(true)} className="validations">
              &nbsp;&nbsp;&nbsp;<b>R</b><GetValidation data={validations?.rb}/>
              &nbsp;&nbsp;<b>E</b><GetValidation data={validations?.eb}/>&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            }
            <div className="actions">
              <Link to={
                (!local && isOnline) ? "/references/edit/"+referenceId
                : (local) && "/synchro/edit/"+referenceId
                } key={referenceId}><span className='picto_admin edit spec2 d-inline-block'></span></Link>
            </div>
          </div>
          :
          <div className="admin-links">
            <div className="infos">Réf.<b>{refId}</b></div>
          </div>
        }
    </div>
    )
}

export default ReferenceFiche