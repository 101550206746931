import { useState, useEffect } from "react"
import axios from 'axios'
import {mess1List, mess2List, APICALL_URL} from '../hooks/data'
import { errorAff } from '../hooks/functions'

function Logs({action, isOnline}){
    const userLocal = JSON.parse(localStorage.getItem("user"))

    const [logs, setLogs] = useState(mess2List[0])
    const [mess1, setMess1] = useState(mess1List[0])
    const [mess2, setMess2] = useState(mess2List[0])
    const [success, setSuccess] = useState(false)
    const [errMsg, setErrMsg] = useState('')

    useEffect ((e)=>{
        loadItems()
    },[])

    const loadItems = async(e) => {
        setSuccess(false)
        const postData = {
          action:"getLogs",
          accessToken: userLocal.accessToken,
          userId: userLocal.idUser
        }
        try {
            const response = await axios.post(APICALL_URL,postData,
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            if(response?.data?.retour){
                console.log(response?.data?.logs)
                setLogs(response?.data?.logs)
                setSuccess(true)
            }
        } catch (err) {
            setErrMsg(errorAff(err))
        }
    }
    
    return(
    <div className="container fs08">
        { isOnline ?
            <>
            { !success && <p className="mess">{mess1}</p>}
            { success && <p className="mess mess_vert mess_court">{mess2}</p>}
            { errMsg!=="" && <p className="mess alerte">{errMsg}</p>}
            <h4>Historique des actions</h4>
            <div>
                { success &&
             logs?.map(log => 
             <div className="row itemLog" key={log.id}>
             <div className="col-4">{log.date} à {log.heure}</div>
             <div className="col-4">{log.user.prenom} {log.user.nom}</div>
             <div className="col-4">{log.phrase}</div>
              </div>
              )
            }
              </div>
            </>
         :
         <div>Vous devez être connecté à internet</div> }
    </div>
    )
}

export default Logs