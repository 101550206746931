import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate=useNavigate()
  const user = JSON.parse(localStorage.getItem("user"))
  useEffect(e=>(
    user?.accessToken && navigate('references')
  ))
  return <div className='container h100'>
  <section className='box h100'>
    <Link to="/login">
      <Button className="btn btn-light btn-lg">
        ENTREZ
      </Button>
    </Link>
    </section>
    </div>
}

export default Home