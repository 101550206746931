import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

function RequireAuth({allowedRoles}){
    const { auth } = useAuth()
    const location = useLocation()

    // Verif si user dans local storage
    var valideUserLocal=false
    const userLocal = JSON.parse(localStorage.getItem("user"))
    if(userLocal?.roles!=null) {
        if(Array.from(allowedRoles).includes(userLocal?.roles)) valideUserLocal=true
    }

    return (
        Array.from(allowedRoles).includes(auth.roles) || valideUserLocal
            ? <Outlet />
            : auth?.user || userLocal?.accessToken //Si authentification réussie ou accessToken dans LS
            ? <Navigate to="/unauthorized" state={{ from: location }} replace />
            : <Navigate to="/login" state={{ from: location }} replace />
    )
}

export default RequireAuth