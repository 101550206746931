import { useState, useEffect } from 'react'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import { Button, Modal,Tabs,Tab } from 'react-bootstrap'
import { XLg, CheckLg, CloudCheck, Trash } from "react-bootstrap-icons"
import {mess1List, mess2List, APICALL_URL, IMGS_URL, auteurs, phases, zones, rangees, niveaux } from '../hooks/data'
import { errorAff, paramMajBddLocale, dateToFr, nl2br } from '../hooks/functions'
import { db } from '../hooks/db'

function CaisseEdit({page, refId, isOnline}){
    const userLocal = JSON.parse(localStorage.getItem("user"))
    const [success, setSuccess] = useState(false)
    const [mess1, setMess1] = useState(mess1List[0])
    const [mess2, setMess2] = useState(mess2List[0])
    const [errMsg, setErrMsg] = useState('')
    const [save, setSave] = useState(false)
    const [saveAndClose, setSaveAndClose] = useState(false)
    const [type, setType] = useState(2)
    const [poids, setPoids] = useState("")
    const [longueur, setLongueur] = useState("")
    const [profondeur, setProfondeur] = useState("")
    const [hauteur, setHauteur] = useState("")
    const [lieu, setLieu] = useState("")
    const [zone, setZone] = useState("")
    const [rangee, setRangee] = useState("")
    const [niveau, setNiveau] = useState("")
    const [infoStockage, setInfoStockage] = useState("")
    const [listeLieux, setListeLieux] = useState([])
    const [loaded, setLoaded] = useState(false)

    useEffect(()=> {
        loadLieux()
        loadCaisse()
    },[])

    useEffect(()=>{
        if(save || saveAndClose) {
            setSuccess(false)
            setMess1(mess1List[1])
            setMess2(mess2List[1])
            const postData = {
                action:"saveCaisseInfos",
                referenceId:refId,
                type:type,
                poids:poids,
                dimensions:JSON.stringify([parseInt(longueur),parseInt(profondeur),parseInt(hauteur)]),
                lieu:lieu,
                zone:zone,
                rangee:rangee,
                niveau:niveau,
                infoStockage:infoStockage,
                accessToken: userLocal.accessToken,
                userId: userLocal.idUser
            }
            axios.post(APICALL_URL,postData,
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            })
            .then(res => {
                setSave(false)
                setSuccess(true)
                majCaissesLocal()
                saveAndClose && navigate(-1)
            })
        }
     },[save,saveAndClose])

    const navigate = useNavigate()
    const cancel = () => { navigate(-1)}

    function majCaissesLocal(){
        const postData = {
            action:"getParametres",
            referenceId:0,
            accessToken: userLocal.accessToken,
            userId: userLocal.idUser
          }
        axios.post(APICALL_URL,postData,
          {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true
          })
          .then(res => {
              paramMajBddLocale(res.data.bdd)
          })
    }

    function loadCaisse(){
        if(isOnline && refId!=="001"){
              const postData = {
                action:"getCaisseInfos",
                referenceId:refId,
                accessToken: userLocal.accessToken,
                userId: userLocal.idUser
              }
            axios.post(APICALL_URL,postData,
              {
                  headers: { 'Content-Type': 'application/json' },
                  withCredentials: true
              })
              .then(res => {
                let caisse=res.data.caisse
                let dims=JSON.parse(caisse.dimensions)
                setType(caisse.type)
                setLongueur(dims[0])
                setProfondeur(dims[1])
                setHauteur(dims[2])
                setPoids(caisse.poids)
                setLieu(caisse.lieu)
                setZone(caisse.zone)
                setRangee(caisse.rangee)
                setNiveau(caisse.niveau)
                setInfoStockage(caisse.infos)
                setLoaded(true)
                setSuccess(true)
              })
          } else {
            setLoaded(true)
            setSuccess(true)
          }
    }

    function loadLieux(){
        db.lieux.where('id').above(0).sortBy("id").then(
            res=>{
              let tempArray=[]
              res.map(car=>{
                tempArray[car.id]=car
              })
              setListeLieux(tempArray)
            })
    }

    const navig = <div className='barre_save'>
      <div className='container tar'>
      <Button className='mgr1' size="sm" variant="secondary" disabled={!loaded && "disabled"} onClick={cancel}>
          <XLg/> Fermer
      </Button>

      <Button className='mgr1' size="sm" variant="primary" disabled={!loaded && "disabled"} onClick={e=>setSaveAndClose(true)}>
          <CloudCheck/>  Enregistrer et fermer
      </Button>
      <Button className='mgr1' size="sm" variant="primary" disabled={!loaded && "disabled"} onClick={e=>setSave(true)}>
      <CloudCheck/>  Enregistrer
      </Button>
      </div>
</div>

    return (
        <div className='container'>
            {navig}
            { !success && <p className="mess">{mess1}</p>}
            { success && <p className="mess mess_vert mess_court">{mess2}</p>}
            { errMsg!=="" && <p className="mess alerte">{errMsg}</p>}
            <div className='fs08'>
            {refId!=="001"
            ?
            <>
            <b>Référence : C{refId}</b><br/>
            </>
            :
            <b>Nouvelle référence</b>}
          </div>
        <Tabs defaultActiveKey="first" className='mt-4'>
        <Tab eventKey="first" title="Identification & Caractéristiques">
            <div className='row'>
                <div className="col-md-3 mt-4">
                    <h4>Identification</h4>
                    <div className="row pt-2">
                        <div className="col-4"><label><b>Type</b></label></div>
                        <div className="col-8">
                            <select className='w100' value={type} onChange={e=>setType(e.target.value)}>
                                <option value="1">Transport</option>
                                <option value="2">Stockage</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-md-1 mt-4"></div>
                <div className="col-md-5 mt-4">
                    <h4>Caractéristiques</h4>
                    <div className="row pt-2">
                        <div className="col-5"><label><b>Poids</b> (kg)</label></div>
                        <div className="col-7 mb-3"><input className='w100' type="number" value={poids} onChange={e=>setPoids(e.target.value)}/></div>
                        <div className="col-5"><label><b>Longueur</b> (cm)</label></div>
                        <div className="col-7 mb-3"><input className='w100' type="number" value={longueur} onChange={e=>setLongueur(e.target.value)}/></div>
                        <div className="col-5"><label><b>Profondeur</b> (cm)</label></div>
                        <div className="col-7 mb-3"><input className='w100' type="number" value={profondeur} onChange={e=>setProfondeur(e.target.value)}/></div>
                        <div className="col-5"><label><b>Hauteur</b> (cm)</label></div>
                        <div className="col-7"><input className='w100' type="number" value={hauteur} onChange={e=>setHauteur(e.target.value)}/></div>
                    </div>
                </div>
            </div>
            </Tab>
            <Tab eventKey="second" title="Localisation">
                <div className="row">
                <div className="col-md-6 mt-4">
                <h4>Localisation</h4>
                <div className="row pt-2">
                <div className="col-5">
                  <label><b>Lieu de stockage</b></label>
                </div>
                <div className="col-7 mb-3">
                  <select name="lieu" value={lieu} onChange={e => setLieu(e.target.value)} className="w100">
                    <option value='0'>Choisir…</option>
                    {listeLieux.map(lieu=><option key={lieu.id} value={lieu.id}>{lieu.nom}</option>)}
                  </select>
                </div>
                <div className="col-5">
                <label><b>Zone</b></label>
                </div>
                <div className="col-7 mb-3">
                <select name="zone" value={zone} onChange={e => setZone(e.target.value)} className="w100">
                    <option value=''>Choisir…</option>
                    {zones.map(res=><option key={res} value={res}>{res}</option>)}
                </select>
                </div>
                <div className="col-5">
                <label><b>Rangée</b></label>
                </div>
                <div className="col-7 mb-3">
                <select name="rangee" value={rangee} onChange={e => setRangee(e.target.value)} className="w100">
                    <option value=''>Choisir…</option>
                    {rangees.map(res=><option key={res} value={res}>{res}</option>)}
                </select>
                </div>
                <div className="col-5">
                <label><b>Niveau</b></label>
                </div>
                <div className="col-7 mb-3">
                <select name="niveau" value={niveau} onChange={e => setNiveau(e.target.value)} className="w100">
                    <option value=''>Choisir…</option>
                    {niveaux.map(res=><option key={res} value={res}>{res}</option>)}
                </select>
                </div>
                <div className="col-12">
                <label><b>Infos stockage</b></label>
                <textarea value={infoStockage} onChange={e=>setInfoStockage(e.target.value)} className="w100 mt-2" />
                </div>
              </div>
            </div>
                </div>
            </Tab>
        </Tabs>
        
        </div>
    )

}

export default CaisseEdit