import {useParams} from 'react-router-dom';
import Layout from '../components/Layout';

function Reference({page}){
    const { refId } = useParams();
    return(
        <Layout page={page} refId={refId} />
    )
}

export default Reference