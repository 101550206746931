export const version="1"

export const dateMaj="28/03/23"

export const mess1List=["Chargement des données en cours","Enregistrement en cours","Suppression en cours","Enregistrement local en cours"]

export const mess2List=["Chargement des données effectué","Enregistrement effectué","Suppression effectuée","Enregistrement local effectué"]

export const APICALL_URL=process.env.REACT_APP_API_ROOT+"/apicall_v1.php"

export const PDFCALL_URL=process.env.REACT_APP_API_ROOT+"/export_pdf_v2.php"

export const IMGS_URL=process.env.REACT_APP_API_ROOT+"/medias/photos/"

export const DOCS_URL=process.env.REACT_APP_API_ROOT+"/medias/docs/"

export const PDFS_URL=process.env.REACT_APP_API_ROOT+"/medias/pdf/"

export const auteurs=[{id:1,nom:"Ronan Bouroullec"}, {id:2,nom:"Erwan Bouroullec"}, {id:3,nom:"Ronan et Erwan Bouroullec"}]

export const listeAuteurs={1:"Ronan Bouroullec",2:"Erwan Bouroullec",3:"Ronan et Erwan Bouroullec"}

export const phases=[{id:1,nom:"Esquisse"},{id:3,nom:"Prototype"},{id:4,nom:"Production"}]

export const listePhases={1:"Esquisse",3:"Prototype",4:"Production"}

export const zones=["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"]

export const rangees=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]

export const niveaux=[0,1,2,3,4,5,6,7,8,9,10]

export const statuts=["Non validé","Non traité","Dans le stock","Dans la corbeille"]

export const listeInitiales={22:"rb",23:"eb"}

export const prenoms={22:"Ronan",23:"Erwan"}

export const filtres1 = [
    {texte:'Tous statuts',val:[{champ:"id",op:">",val:0,type:"int"}],active:true},
    {texte:'Non validé',val:[{champ:"id",op:">",val:0,type:"int"},{champ:"statut",op:"=",val:0,type:"int"}],active:false},
    {texte:'Non traité',val:[{champ:"id",op:">",val:0,type:"int"},{champ:"statut",op:"=",val:1,type:"int"}],active:false},
    {texte:'Dans le stock',val:[{champ:"id",op:">",val:0,type:"int"},{champ:"statut",op:"=",val:2,type:"int"}],active:false},
    {texte:'Dans la corbeille',val:[{champ:"id",op:">",val:0,type:"int"},{champ:"statut",op:"=",val:3,type:"int"}],active:false},
    {texte:'Tous les types',val:[{champ:"type",op:">",val:0,type:"int"}],active:true},
    {texte:'Caisses de stockage',val:[{champ:"type",op:"=",val:1,type:"int"}],active:true},
    {texte:'Caisses de transport',val:[{champ:"type",op:"=",val:2,type:"int"}],active:true},
]

export const ordres1 = [
    {texte:'Plus récent au plus ancien',val:{champ:"id",sens:"DESC"},active:true},
    {texte:'Plus ancien au plus récent',val:{champ:"id",sens:"ASC"},active:false},
    {texte:'Plus récent au plus ancien',val:{champ:"id",sens:"DESC"},active:true},
    {texte:'Plus ancien au plus récent',val:{champ:"id",sens:"ASC"},active:false}
]

export const listeTypeRech=[{id:0,val:"Recherche par identification"},{id:1,val:"Recherche dans caractéristiques et description"},{id:2,val:"Recherche par référence"},{id:3,val:"Recherche dans l'indexation générale"}]