import Dexie from "dexie";

export const db = new Dexie('erb');
db.version(29).stores({// incrémenter version à chaque maj de la structure
  categories: '++id, nom, ordre',
  familles: '++id, cat, nom, ordre',
  typologies: '++id, cat, nom, ordre',
  editeurs: '++id, cat, nom, ordre',
  caracteristiques: '++id, cat, nom, ordre, [id+cat]',
  lieux: '++id, nom',
  stockage_types: '++id, nom',
  objets: '++id, categorie, famille, typologie, phase, auteur, editeur, description, caracteristiques, statut, stock_state, stock_type, stock_caisse, file',
  stockage: '++id, id_ref, lieu, zone, rangee, niveau, infos',
  medias: '++id, id_ref, type, nom, extension, poids, titre, ordre, prev, rotation, [id_ref+type]',
  caisses: '++id'
});