import { useState, useEffect } from "react"
import axios from 'axios'
import { Button, Modal } from 'react-bootstrap'
import { XLg, CheckLg, Trash, Save2 } from "react-bootstrap-icons"
import {mess1List, mess2List, APICALL_URL} from '../hooks/data'
import { errorAff } from '../hooks/functions'

function Lieux({action, isOnline}){
    const userLocal = JSON.parse(localStorage.getItem("user"))
    const [nomLieu, setNomLieu] = useState()
    const [idItem, setIdItem] = useState()
    const [lieux, setLieux] = useState()
    const [mess1, setMess1] = useState(mess1List[0])
    const [mess2, setMess2] = useState(mess2List[0])
    const [texteItem, setTexteItem] = useState()
    const [success, setSuccess] = useState(false)
    const [errMsg, setErrMsg] = useState("")
    const [show, setShow] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [localAction, setLocalAction]=useState()
    const [disable, setDisable] = useState(false)

    
    useEffect ((e)=>{
        loadItems()
    },[])

    const handleClose = () => setShow(false)
    const handleCloseConfirm = () => setShowConfirm(false)

    function showModifItem(lieu){
        setDisable(true)
        setIdItem(lieu.id)
        setNomLieu(lieu.nom)
        setLocalAction("modifItem")
        setShow(true)
        setTexteItem(lieu.nom)
    }
    function showAddItem(){
        setDisable(true)
        setLocalAction("addItem")
        setShow(true)
    }

    const addItem = async (e) => {
        e.preventDefault()
        setMess1(mess1List[1])
        setMess2(mess2List[1])
        if(nomLieu.replace(/ /g, "")==="") return false
        const postData = {
            action:"addLieu",
            nom:nomLieu,
            accessToken: userLocal.accessToken,
            userId: userLocal.idUser
          }
        try {
            const response = await axios.post(APICALL_URL,postData,
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            if(response?.data?.retour){
                loadItems()
                setNomLieu("")
            }
        } catch (err) {

        }
    }

    const modifItem = async (e) => {
        e.preventDefault()
        setMess1(mess1List[1])
        setMess2(mess2List[1])
        if(nomLieu.replace(/ /g, "")==="") return false
        const postData = {
            action:"modifLieu",
            id:idItem,
            nom:nomLieu,
            accessToken: userLocal.accessToken,
            userId: userLocal.idUser
          }
        try {
            const response = await axios.post(APICALL_URL,postData,
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            if(response?.data?.retour){
                console.log(response)
                loadItems()
                setNomLieu("")
            }
        } catch (err) {
            console.log(err)
        }
    }

    const supItem = async (e) => {
        e.preventDefault()
        setShow(false)
        setShowConfirm(true)
    }

    const supItemConfirm = async (e) => {
        e.preventDefault()
        setMess1(mess1List[2])
        setMess2(mess2List[2])
        const postData = {
            action:"supLieu",
            id:idItem,
            accessToken: userLocal.accessToken,
            userId: userLocal.idUser
          }
        try {
            const response = await axios.post(APICALL_URL,postData,
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            if(response?.data?.retour){
                setShowConfirm(false)
                loadItems()
            }
        } catch (err) {
            console.log(err)
        }
    }
    const refreshPage=(e) => {
        setMess1(mess1List[0])
        setMess2(mess2List[0])
        loadItems()
    }
    
    const loadItems = async (e) => {
       // e.preventDefault()
       setSuccess(false)
        const postData = {
          action:"getLieux",
          accessToken: userLocal.accessToken,
          userId: userLocal.idUser
        }
        try {
            const response = await axios.post(APICALL_URL,postData,
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            if(response?.data?.retour){
                console.log(response?.data?.lieux)
              setLieux(response?.data?.lieux)
              setSuccess(true)
              setShow(false)
            }
        } catch (err) {
            setErrMsg(errorAff(err))
        }
      }

      const confirm=<Modal show={showConfirm} onHide={handleCloseConfirm} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirmez la suppression</Modal.Title>
      </Modal.Header>
      <Modal.Body>Voulez-vous vraiment supprimer le lieu <b>{nomLieu }</b> ?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseConfirm}>
        <XLg /> Annuler
        </Button>
        <Button variant="primary" onClick={supItemConfirm}>
          <CheckLg/> Oui
        </Button>
      </Modal.Footer>
    </Modal>
    const modal=<Modal show={show} onHide={handleClose} centered>
    <Modal.Header closeButton>
      <Modal.Title>
      { localAction==="addItem" && <span>Ajouter un lieu de stockage</span> }
      { localAction==="modifItem" && <span>{texteItem}</span> }
        </Modal.Title>
    </Modal.Header>
    <Modal.Body>
        {localAction==="addItem" &&
    <form>
        <label className="w100">Nom du lieu</label>
        <input className="w100"onChange={(e) => {setNomLieu(e.target.value); setDisable(false)}} type="text"></input>
    </form>
    }
    {localAction==="modifItem" &&
    <form>
        <label className="w100">Nom du lieu</label>
        <input className="w100"onChange={(e) => {setNomLieu(e.target.value); setDisable(false)}} type="text" value={nomLieu}></input>
    </form>
    }
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
      <XLg />&nbsp;&nbsp;Annuler
      </Button>
      {localAction==="modifItem" &&
      <>
        <Button variant="danger" onClick={supItem}>
        <Trash />&nbsp;&nbsp;Supprimer
        </Button>
        <Button variant="primary" onClick={modifItem} disabled={disable}>
            <Save2/>&nbsp;&nbsp;Modifier
        </Button>
     </>
    }
    {localAction==="addItem" &&
        <Button variant="primary" onClick={addItem} disabled={disable}>
           <CheckLg/> Ajouter
        </Button>
    }
    </Modal.Footer>
  </Modal>

    return(
    <div className="container">
      { isOnline ? 
      <>
        { modal }
        { confirm }
        { !success && <p className="mess">{mess1}</p>}
        { success && <p className="mess mess_vert mess_court">{mess2}</p>}
        { errMsg!=="" && <p className="mess alerte">{errMsg}</p>}
        <div className="tar"><span className="picto_ssmenu update sc07" onClick={refreshPage}></span></div>
        <h4>Lieux de stockage</h4>
        <div>
        { success &&
        lieux?.map(lieu => <div className="itemParam" key={lieu.id} onClick={()=>{showModifItem(lieu)}}>
              <div><b>{lieu.nom}</b></div>
              </div>)
              }
              <div className="bloc_plus mt-2" onClick={()=>{showAddItem()}}><span className="picto_admin plus"></span><span className="texte">Ajouter un lieu</span></div>
              </div>
          </>
      :
      <div>Vous devez être connecté à internet</div> }
    </div>
    )
}

export default Lieux