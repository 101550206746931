import { useState, useEffect } from 'react'
import axios from 'axios'
import {APICALL_URL, PDFCALL_URL} from '../hooks/data'
import { db } from '../hooks/db'
import { Link } from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap'
import { XLg, CheckLg, CloudCheck, Trash } from "react-bootstrap-icons"

function CaisseInfos({refId, edit, refsAff}) {

    const userLocal = JSON.parse(localStorage.getItem("user"))
    const [success, setSuccess] = useState(false)
    const [type, setType] = useState(2)
    const [poids, setPoids] = useState("")
    const [longueur, setLongueur] = useState("")
    const [profondeur, setProfondeur] = useState("")
    const [hauteur, setHauteur] = useState("")
    const [lieu, setLieu] = useState("")
    const [zone, setZone] = useState("")
    const [rangee, setRangee] = useState("")
    const [niveau, setNiveau] = useState("")
    const [infoStockage, setInfoStockage] = useState("")
    const [listeLieux, setListeLieux] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [affInfosPdf,setAffInfosPdf] = useState(false)
    const [linkPdf, setLinkPdf] = useState("")
    const [showExportPdf, setShowExportPdf] = useState(false)

    let types = {1:"transport",2:"stockage"}

    useEffect(()=> {
        loadLieux()
        loadCaisse()
    },[])

    useEffect(() => {
      if(showExportPdf){
          setAffInfosPdf(false)
      }
  },[showExportPdf])

    function loadCaisse(){
              const postData = {
                action:"getCaisseInfos",
                referenceId:refId,
                accessToken: userLocal.accessToken,
                userId: userLocal.idUser
              }
            axios.post(APICALL_URL,postData,
              {
                  headers: { 'Content-Type': 'application/json' },
                  withCredentials: true
              })
              .then(res => {
                let caisse=res.data.caisse
                let dims=JSON.parse(caisse.dimensions)
                setType(caisse.type)
                setLongueur(dims[0])
                setProfondeur(dims[1])
                setHauteur(dims[2])
                setPoids(caisse.poids)
                setLieu(caisse.lieu)
                setZone(caisse.zone)
                setRangee(caisse.rangee)
                setNiveau(caisse.niveau)
                setInfoStockage(caisse.infos)
                setLoaded(true)
                setSuccess(true)
              })
    }

    function loadLieux(){
        db.lieux.where('id').above(0).sortBy("id").then(
            res=>{
              let tempArray=[]
              res.map(car=>{
                tempArray[car.id]=car
              })
              setListeLieux(tempArray)
            })
    }

    function hideExportPdf(){
      setShowExportPdf(false)
  }

    function exportPDF(){
      setAffInfosPdf(false)
      let act=""
          act="export_caisse"
          let listeRefs=[];
          refsAff.map(ref=>listeRefs.push(ref.id))
          const postData = {
              action:act,
              references:listeRefs,
              refCaisse:refId,
              accessToken: userLocal.accessToken,
              userId: userLocal.idUser
            }
          axios.post(PDFCALL_URL,postData,
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            })
            .then(res => {
              let url="https://ronan-erwan-bouroullec-archives.com/api/medias/pdf/caisse-C"+refId+"-references.pdf"
              setLinkPdf(url)
              setAffInfosPdf(true)
              setSuccess(true)
            })
  }

  const modalExportPdf=
    <Modal show={showExportPdf} onHide={hideExportPdf} centered>
    <Modal.Header closeButton>
    <Modal.Title className='w100'>
        Exporter le contenu de la caisse C{refId} en PDF
        </Modal.Title>
    </Modal.Header>
    {affInfosPdf &&
    <Modal.Body>
    <div>
        <b>Votre document est prêt !</b><br/>
        <a href={linkPdf} target="_blank">Télécharger le PDF</a>
    </div>
    </Modal.Body>
    }
    <Modal.Footer>
    <Button variant="secondary" onClick={hideExportPdf}>
        <XLg /> Annuler
    </Button>
    <Button variant="primary" onClick={exportPDF}>
        <CheckLg/> Exporter
    </Button>
    </Modal.Footer>
    </Modal>

    return (
        <div className='fs08 typo1'>
          {modalExportPdf}
            <b>Réf.C{refId}</b> – Caisse de {types[type]}<br/>
            Poids {poids}kg – L.{longueur}cm x P.{profondeur}cm x H.{hauteur} cm<br/>
            {listeLieux[lieu]?.nom}
            {(zone!=="" && zone!=="0") && <span> – zone {zone}</span>}
            {(rangee!=="" && rangee!=="0") && <span> – rangée {rangee}</span>}
            {(niveau!=="" && niveau!=="0") && <span> – niveau {niveau}</span>}
            {edit &&
            <><br/>
            <Link className='btn btn-sm align-baseline' to={"/caisses/edit/"+refId}><p className='picto_admin edit'></p></Link>
            <p onClick={()=>setShowExportPdf(true)} className="picto_admin exportpdf spec1 align-baseline"></p>
            </>
            }
        </div>
    )

}

export default CaisseInfos