import ReferenceFiche from "../ReferenceFiche"
import { useState, useEffect } from "react"
import Recherche from "../Recherche"
import { listeInitiales, prenoms, APICALL_URL } from "../../hooks/data"
import axios from 'axios'

function Over({action, actionChange, listeAff, viewOver, setViewOver,contenu,page,getFiltres,setRech,isOnline,validations,setValidations,statutValidation,setStatutValidation, refs, setRefs, refsAff, setRefsAff}){

    const [ref,setRef] = useState()
    const [refIndex, setRefIndex] = useState()
    const [showValidation, setShowValidation] = useState(false)
    const [validated, setValidated] = useState(false)
    const [sens, setSens] = useState()
    const [statutObj, setStatutObj] = useState()
    const [affZoneMess, setAffZoneMess] = useState(false)
    const [remarques, setRemarques] = useState("")
    const [newMess, setNewMess] = useState(1)

    const userLocal = JSON.parse(localStorage.getItem("user"))
    const initiales=listeInitiales[userLocal.idUser]
    const prenom=prenoms[userLocal.idUser]

    
    useEffect(() => {
        setRef()
        setRefIndex(listeAff.indexOf(contenu.id))
    }, [contenu])

    useEffect(() => {
        if((statutValidation===1 || statutValidation===2 || statutValidation===3 || statutValidation===0) && showValidation){
            let newVal = {...validations}
            newVal[initiales]=statutValidation
            setValidations(newVal)
        } else {
            setShowValidation(false)
        }
    }, [statutValidation])

    useEffect(() => {
        if((statutValidation===1 || statutValidation===2 || statutValidation===3 || statutValidation===0) && showValidation){
            let refId
            let statut=0
            if(!ref) {refId=contenu.id} else {refId=ref}
            if(validations?.eb!=0 && validations?.rb!=0) {
                statut=1
            }
            const postData = {
                action:"updateValidations",
                refId:refId,
                statut:statut,
                validations:JSON.stringify(validations),
                accessToken: userLocal.accessToken,
                userId: userLocal.idUser
            }
            axios.post(APICALL_URL,postData,
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            })
            .then(res => {
                // maj affichage
                let refsTemp=[...refs]
                refsTemp[refIndex].validations=JSON.stringify(validations)
                setRefs(refsTemp)
                setNewMess(newMess+1)
                setShowValidation(false)
            })
        }
    }, [validations])

    useEffect(() => {
        if(!showValidation){
            setAffZoneMess(false)
        }
    },[showValidation])

    function envoiMessage(){
        const current = new Date();
        const date = `${current.getDate()}-${current.getMonth()+1}-${current.getFullYear()}`;
        let mess={"auteurs":userLocal.idUser,"type":1,"cont":remarques,"prenom":userLocal.user,"date":date} // pour maj temps réel
        const postData = {
            action:"envoiMessage",
            type:"1",
            refId:listeAff[refIndex],
            message:remarques,
            nom:userLocal.user,
            accessToken: userLocal.accessToken,
            userId: userLocal.idUser
        }
        axios.post(APICALL_URL,postData,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        })
        .then(res => {
            if(statutValidation!==3) {
                setStatutValidation(3) }
            else {
                setNewMess(newMess+1)// pour maj useeffect dans objet fiche
                setShowValidation(false)
            }
            setRemarques("")
        })
    }

    function navRef(sens){
        setSens(sens)
        let index=refIndex+sens
        if((initiales==="eb" || initiales==="rb") && statutObj==="0" && statutValidation===0 && !validated){
            setShowValidation(true)
        } else {
            setRef(listeAff[index])
            setRefIndex(index)
            setValidated(false)
        }
    }

    function valide(val){
        if(val===statutValidation && val!=3) setShowValidation(false)
        switch (val) {
            case 1 : setStatutValidation(1)
            break
            case 2 : setStatutValidation(2)
            break
            case 0 : setStatutValidation(0)
            break
            case 3 : setAffZoneMess(true)
            break
        }
        setValidated(true)
    }

    return(
        <div className={viewOver ? "overZone" : "d-none"} >
            <div onClick={()=>setViewOver(false)}  className="over-fond"></div>
            <span onClick={()=>setViewOver(false)} className="picto_navig closeBlc closeOver"></span>
            {(contenu.cont==="viewRef" && viewOver) &&
            <div className="container-over">

                {refIndex!==0 ? 
                <div className="navRef navLeft">
                    <span onClick={()=>navRef(-1)} className="picto_navig left1"></span>
                </div> :
                <div className="navRef h0"></div>
                }
                    {!ref &&<ReferenceFiche validations={validations} setValidations={setValidations} setStatutObj={setStatutObj} statutValidation={statutValidation} setStatutValidation={setStatutValidation} setShowValidation={setShowValidation} page={page} refId={contenu.id} isOnline={isOnline} newMess={newMess} />}
                    {ref &&<ReferenceFiche validations={validations} setValidations={setValidations} setStatutObj={setStatutObj} statutValidation={statutValidation} setStatutValidation={setStatutValidation} setShowValidation={setShowValidation} page={page} refId={ref} isOnline={isOnline} newMess={newMess} />}
                {refIndex!==(listeAff.length-1) ? 
                <div className="navRef navRight">
                    <span onClick={()=>navRef(1)} className="picto_navig right1"></span>
                </div> :
                <div className="navRef pd0 h0"></div>
                }
            </div>
             }
             {(contenu.cont==="viewRefLocal" && viewOver) &&
            <div className="container-over">
            <ReferenceFiche page={page} refId={contenu.id} isOnline={isOnline} local={true} />
            </div>
             }
             {(contenu.cont==="search" && viewOver) &&
             <div className="container-over">
             <Recherche type="ref" setViewOver={setViewOver} getFiltres={getFiltres} setRech={setRech} />
             </div>
            }
            {(showValidation && userLocal.roles===2001) &&
            <div className="zone-validation">
                <div className="bloc-validation w100 tac">
                    <div className="p-3">
                        {prenom}, quel est votre avis concernant l'archivage de cette référence&nbsp;?
                        <div className="btns-validation mt-4 tac">
                        <button className="btn btn-spec1 btn-success" onClick={e=>valide(1)}>On garde</button><br/>
                        <button className="btn btn-spec1 btn-danger mt-2" onClick={e=>valide(2)}>On jette</button><br/>
                        <button className="btn btn-spec1 btn-dark mt-2" onClick={e=>valide(3)}>Je fais des remarques</button><br/>
                        {affZoneMess &&
                        <div className="zoneMess w100">
                            <textarea autoFocus rows="4" value={remarques} onChange={(e)=>setRemarques(e.target.value)} className="w100" placeholder="Saississez ici vos remarques"></textarea>
                            <button className="btn btn-spec1 btn-dark btn-sm" onClick={()=>envoiMessage()}>Envoyer mes remarques</button>
                        </div>
                        }
                        <button className="btn btn-spec1 btn-secondary mt-2" onClick={e=>valide(0)}>Je déciderai plus tard</button><br/>
                        <button className="btn btn-spec1 btn-outline-dark mt-2" onClick={e=>setShowValidation(false)}>X Annuler</button>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}
export default Over